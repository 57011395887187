<template>
  <v-form ref="editorContainer">
    <v-row>
      <v-col cols="6" md="6" sm="6">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 700;
            font-size: 31px;
            line-height: 51px;
          "
        >
          ติดต่อ
        </span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="6" md="6" sm="6" class="text-right">
        <v-btn
          @click="submit('q_1_1')"
          color="#2AB3A3"
          dark
          style="
            padding: 12px 16px;
            gap: 8px;

            width: 158px;
            height: 48px;
          "
          >บันทึก</v-btn
        >
      </v-col>
    </v-row>
    <v-divider style="border: 1px solid #d0d5dd" class="mt-4"></v-divider>
    <v-row>
      <v-col cols="12" md="12" sm="12" xs="12" class="mt-4">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          ข้อมูลการติดต่อ
        </span>
      </v-col>
    </v-row>
    <v-card class="pa-6 mt-4" flat>
      <v-row>
        <v-col cols="12" md="6">
          <span>สถานที่ตั้ง (TH)</span>
          <span style="color: red">*</span>
          <v-text-field
            hide-details
            v-model="form.c_name"
            placeholder="ระบุสถานที่ตั้ง (TH)"
            outlined
            dense
            :rules="rules.required"
            ref="textField"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>สถานที่ตั้ง (EN) </span>
          <v-text-field
            hide-details
            v-model="form.c_Address"
            placeholder="ระบุสถานที่ตั้ง (EN)"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>หมายเลขโทรศัพท์</span>
          <span style="color: red">*</span>
          <v-text-field
            hide-details
            v-model="form.c_phone"
            placeholder="ระบุหมายเลขโทรศัพท์"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>เบอร์แฟกซ์ </span>
          <v-text-field
            hide-details
            v-model="form.c_fax"
            placeholder="ระบุเบอร์แฟกซ์"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>อีเมล์</span>
          <span style="color: red">*</span>
          <v-text-field
            hide-details
            v-model="form.c_email"
            placeholder="ระบุอีเมล์"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6"></v-col>
      </v-row>
    </v-card>

    <v-divider class="mt-10"></v-divider>

    <v-row>
      <v-col cols="12" md="12" sm="12" xs="12" class="mt-5">
        <span
          style="
            font-weight: 800;
            color: #000000;
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          Social Network
        </span>
      </v-col>
    </v-row>

    <v-card class="pa-6 mt-4" flat>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <span>Facebook</span>
          <v-text-field
            hide-details
            v-model="form.facebook"
            placeholder="ระบุลิ้งค์ URL Facebook"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="6"> </v-col>

        <v-col cols="12" md="6" sm="6">
          <span>Youtube</span>
          <v-text-field
            hide-details
            v-model="form.youtube"
            placeholder="ระบุลิ้งค์ URL Youtube"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="6"> </v-col>

        <v-col cols="12" md="6" sm="6">
          <span>Linkedin</span>
          <v-text-field
            hide-details
            v-model="form.linkedin"
            placeholder="ระบุลิ้งค์ URL Linkedin"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="6"> </v-col>

        <v-col cols="12" md="6" sm="6">
          <span>Instagram </span>
          <v-text-field
            hide-details
            v-model="form.instagram"
            placeholder="ระบุลิ้งค์ URL instagram"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="6"> </v-col>

        <v-col cols="12" md="6" sm="6">
          <span>Twitter</span>
          <v-text-field
            hide-details
            v-model="form.twitter"
            placeholder="ระบุลิ้งค์ URL Twitter"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="6"></v-col>

        <v-col cols="12" md="6" sm="6">
          <span>Tiktok</span>
          <v-text-field
            hide-details
            v-model="form.tiktok"
            placeholder="ระบุลิ้งค์ Tiktok"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="6"></v-col>

        <v-col cols="12" md="6" sm="6">
          <span>Line</span>
          <v-text-field
            hide-details
            v-model="form.line"
            placeholder="ระบุลิ้งค์ Line"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="6"></v-col>
      </v-row>
    </v-card>

    <v-divider class="mt-10"></v-divider>
    <v-row>
      <v-col cols="12" md="12" sm="12" xs="12" class="mt-5">
        <span
          style="
            font-weight: 800;
            color: #000000;
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          การเดินทาง
        </span>
      </v-col>
    </v-row>

    <v-card class="pa-6 mt-4" flat>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <span>รถยนต์ (TH)</span>
          <span style="color: red">*</span>
          <quill-editor
            class="quill233"
            v-model="form.travelByCar_th"
            :content="content"
            :options="editorOption"
            @change="onEditorChange($event)"
          />
          <!-- <v-textarea
            v-model="form.travelByCar_th"
            placeholder="ระบุการเดินทางโดยรถยนต์ (TH)"
            outlined
            dense
            :rules="rules.required"
            hide-details
          ></v-textarea> -->
        </v-col>

        <v-col cols="12" md="6" sm="6">
          <span>รถยนต์ (EN)</span>
          <quill-editor
            class="quill233"
            v-model="form.travelByCar_en"
            :content="content1"
            :options="editorOption"
            @change="onEditorChange1($event)"
          />
          <!-- <v-textarea
            v-model="form.travelByCar_en"
            placeholder="ระบุการเดินทางโดยรถยนต์ (EN)"
            outlined
            dense
            hide-details
          ></v-textarea> -->
        </v-col>

        <v-col cols="12" md="6" sm="6">
          <span>รถประจำทาง (TH)</span>
          <span style="color: red">*</span>
          <quill-editor
            class="quill233"
            v-model="form.travelByBus_th"
            :content="content2"
            :options="editorOption"
            @change="onEditorChange2($event)"
          />
          <!-- <v-textarea
            v-model="form.travelByBus_th"
            placeholder="ระบุการเดินทางโดยรถประจำทาง (TH)"
            outlined
            dense
            :rules="rules.required"
            hide-details
          ></v-textarea> -->
        </v-col>

        <v-col cols="12" md="6" sm="6">
          <span>รถประจำทาง (EN)</span>
          <quill-editor
            class="quill233"
            v-model="form.travelByBus_en"
            :content="content3"
            :options="editorOption"
            @change="onEditorChange3($event)"
          />
          <!-- <v-textarea
            v-model="form.travelByBus_en"
            placeholder="ระบุการเดินทางโดยรถประจำทาง (EN)"
            outlined
            dense
            hide-details
          ></v-textarea> -->
        </v-col>

        <v-col cols="12" md="6" sm="6">
          <span>เรือ (TH)</span>
          <quill-editor
            class="quill233"
            v-model="form.travelByBoat_th"
            :content="content4"
            :options="editorOption"
            @change="onEditorChange4($event)"
          />
          <!-- <v-textarea
            v-model="form.travelByBoat_th"
            placeholder="ระบุการเดินทางโดยเรือ (TH)"
            outlined
            dense
            hide-details
          ></v-textarea> -->
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <span>เรือ (EN)</span>
          <quill-editor
            class="quill233"
            v-model="form.travelByBoat_en"
            :content="content5"
            :options="editorOption"
            @change="onEditorChange5($event)"
          />
          <!-- <v-textarea
            v-model="form.travelByBoat_en"
            placeholder="ระบุการเดินทางโดยเรือ (EN)"
            outlined
            dense
            hide-details
          ></v-textarea> -->
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <span>Google Map</span>
          <span style="color: red">*</span>
          <v-text-field
            hide-details
            v-model="form.googleMapLink"
            placeholder="ระบุลิ้ง Google Map"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>

<script>
import { Decode } from "@/services";

export default {
  data() {
    return {
      form: {
        c_name: "",
        c_Address: "",
        c_email: "",
        c_fax: "",
        c_phone: "",

        facebook: "",
        youtube: "",
        linkedin: "",
        instagram: "",
        twitter: "",
        tiktok: "",
        line: "",

        travelByCar_th: "",
        travelByCar_en: "",
        travelByBus_th: "",
        travelByBus_en: "",
        travelByBoat_th: "",
        travelByBoat_en: "",
        googleMapLink: "",
      },
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },

      content: "",
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",

      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  created() {
    this.getData();
    document.title = "ติดต่อ - GSCM Management System";
  },

  methods: {
    focusTextField() {
      this.$nextTick(() => {
        this.$refs.textField.focus(); // Use $refs to access the text field element and call the focus method
      });
    },
    onEditorChange({ quill, html, text }) {
      this.content = html;
    },
    onEditorChange1({ quill, html, text }) {
      this.content1 = html;
    },
    onEditorChange2({ quill, html, text }) {
      this.content2 = html;
    },
    onEditorChange3({ quill, html, text }) {
      this.content3 = html;
    },
    onEditorChange4({ quill, html, text }) {
      this.content4 = html;
    },
    onEditorChange5({ quill, html, text }) {
      this.content5 = html;
    },
    async getData() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/c_configs/2`
      );
      console.log("response", response.data.data);
      this.form = response.data.data;

      this.focusTextField(); // Call the method to focus the text field when the component is mounted
    },
    async submit() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/c_configs/2`,
        this.form,
        auth
      );
      console.log("response", response);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขข้อมูลสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        await this.getData();
      } else {
        this.$swal.fire({
          icon: "error",
          text: response.data.response_description,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>